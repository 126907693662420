body{
  background-color:#fdffec;
}

a{
  color: inherit;
  text-decoration: none;
}

a:hover{
  color: #5654cc;
}

img{

}

h1{

    font-weight: normal;
    font-size: 2vw;

}

hr {
  border: none;
  height: 0.1vw;
  color: #303030;
  background-color: #ad7411;  /* Modern Browsers */
  border-radius: 7px 7px 7px 7px;
}

.rotate {
  animation: rotation 40s infinite linear;
  position: absolute;
  right:4%;
  top:1%;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg) rotateZ(0deg);
  }
  to {
    transform: rotateY(359deg) rotateZ(359deg);
  }
}

.websites{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}


.container {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  right: 5%;
  width: 50%;
  text-align: right;
  font-family: Futura;
  font-weight: normal;
  color: #303030;
  font-size: 0.2vw;
}

.text {
  font-size: 1.5vw;
  font-weight: bold;
  color: #333;
  font-family: Andale mono; 

}

.header {
  font-family: "Helvetica"; 
  font-style:oblique;
  position: absolute;
  top: 5%;
  left: 2%;
  text-align: left;
  font-size: 5vw;
  font-weight: bold;
  color: #00006c;
}

.toolBar{
  font-family: "Futura";
  color: #ad7411;
  font-size: 1vw;
  font-weight: bold;
  position: absolute;
  top: 2%;
  right: 2%;
  /*
  top: 14%;
  left: 23.5%; */
}
.mainContent::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);        /* color of the tracking area */
  width: 0.3vw;
}

.mainContent::-webkit-scrollbar-thumb {
  background: #ad7411;
  width:10%;  
  border-radius: 20vw;   
  max-height: 0.4vw; 
  height: 0.4vw;  
}

.mainContent{
  position: absolute;
  font-family: "Avenir";  
  font-style:book;
  top: 42%;
  left: 10%;
  text-align: left  ;
  -ms-overflow-style: none; 
  scrollbar-width: none;  
  overflow: overlay;
  width:50%;
  height:50%;
  font-size: 1.5vw;
  padding-right: 2vw;
}

.projectName{
  color: #5654cc;
  font-family: "Futura";
  font-weight: bold;
  font-size: 5vw;
}

.listName{
  color: #5654cc;
  font-family: "Futura";
  font-weight: bold;
  font-style:italic;
  font-size: 1vw;
}
